/**
 * Call Marketo RTP function to get user location
 */
rtp("get", "visitor", (data) => {
	// Check for success results
	if (data.status !== 200) {
		return false;
	}

	// Get state of user
	const state = data.results.location.state;

	// If user is not in VA hide VA only content
	if (state !== "VA") {
		// Get element to hide
		const inStateEl = document.getElementById("js-in-state");

		// if element found hide element
		if (inStateEl) {
			inStateEl.style.display = "none";
		}
	}
});
